import React, { useEffect, useState  } from "react"
import { Link } from "gatsby"
import Slider from "../components/Slider"

import "../assets/css/menu.css"
import teamImg from "../images/roup.jpg"

const Advertising = () => {
  const url = typeof window !== "undefined" ? window.location.pathname : "";
  const [isVisibleMore, setIsVisibleMore] = useState(false);

  useEffect(() => {
    if (url.split('/')[1] !== "advertising") {
      window.history.pushState({}, "", `/advertising`)
    }
  }, [])

  return (
    <>
      <div className="w-screen overflow-x-hidden">
        <Slider pageType={'ads'} isVisibleMore={isVisibleMore}/>
          { !isVisibleMore && (<button
            onClick={() => {
              setIsVisibleMore(true);
            }}
            style={{ width: '100%', position: 'absolute' }}
            className="button-2 my-8 px-0 inline py-2 object-center text-md text-center font-medium leading-5 text-black tracking-widest transition-colors duration-150 focus:outline-none focus:shadow-outline-blue bg-blue-600 active:bg-blue-600 hover:bg-blue-700"
          >
          <>SHOW MORE
            <svg
              id="Layer_1"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              width="340"
              height="25.81"
              viewBox="0 0 340 26"
              style={{ position: "relative", width: '100%' }}
            >
              <path
                d="M340,.14c-1.42,9.76-9.82,17.26-19.98,17.26H0"
                style={{
                  fill: "none",
                  stroke: "#000",
                  strokeMiterlimit: 10,
                  strokeWidth: 1,
                }}
              />
            </svg>
          </>
        </button>)}

      </div>
      <section className="w-screen bg-white">
        <div className="hidden md:grid absolute w-4/12 h-4/6  right-0 bg-blue blue-cut"></div>
        <div className="md:hidden absolute w-2/12 h-1/6 bg-blue blue-cut-1"></div>

        <div className="container py-28 px-6">
          <div className="grid grid-cols-1 md:mx-32">
            <div>
              <p className="text-ads-1 max-w-3xl text-4xl md:text-3xl  leading-normal tracking-widest text-start text-black">
                MODERN. AWARD-WINNING. INDEPENDENT. CONNECTING ICONIC BRANDS
                WITH AUDIENCES WORLDWIDE THROUGH INNOVATIVE STORYTELLING.
              </p>
              <button className="button-ads-1 my-8 object-center px-0 inline py-2 text-md font-medium leading-5 text-black tracking-widest transition-colors duration-150 focus:outline-none focus:shadow-outline-blue bg-blue-600 active:bg-blue-600 hover:bg-blue-700">
                <Link to="/about-us">MORE ABOUT US
                <svg
                  id="Layer_1"
                  data-name="Layer 1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="340"
                  height="25.81"
                  viewBox="0 0 340 26"
                  style={{ position: "relative" }}
                >
                  <path
                    d="M340,.14c-1.42,9.76-9.82,17.26-19.98,17.26H0"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeMiterlimit: 10,
                      strokeWidth: 1,
                    }}
                  />
                </svg>
                </Link>
              </button>
            </div>
          </div>
        </div>
      </section>

      <section className="w-screen bg-white py-18 px-6" style={{overflow: 'hidden'}}>
        <div className="container mx-auto">
          <div className="advertising-block mx-auto max-w-2xl pt-10 pb-16 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-6 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8 lg:px-8 lg:pt-16 lg:pb-24 items-center">
            <div
              className="col-span-3"
              src={teamImg}
              alt="Workflow"
            />
            <div className="col-span-3">
              <p className="text-ads-2 pt-12 text-4xl 3xl:text-3xl font-light leading-normal tracking-widest text-start text-black">
                We are an Award-Winning team of experienced creatives, producers
                and technicians, crafting experiences for audiences worldwide.
              </p>
              <button className="button-ads-1 my-8 inline px-0 py-2 object-center text-md font-medium leading-5 text-black tracking-widest transition-colors duration-150 focus:outline-none focus:shadow-outline-blue bg-blue-600 active:bg-blue-600 hover:bg-blue-700">
                <Link to="/about-us/#ag-team">MEET THE TEAM
                <svg
                  id="Layer_1"
                  data-name="Layer 1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="340"
                  height="25.81"
                  viewBox="0 0 340 26"
                  style={{ position: "relative" }}
                >
                  <path
                    d="M340,.14c-1.42,9.76-9.82,17.26-19.98,17.26H0"
                    style={{
                      fill: "none",
                      stroke: "#000",
                      strokeMiterlimit: 10,
                      strokeWidth: 1,
                    }}
                  />
                </svg>
                </Link>
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Advertising
