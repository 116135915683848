import React from "react"

import Layout from "../components/Layout"
import Navbar from "../components/Navbar"
import Advertising from "../components/Advertising"
import ContactForm from "../components/ContactForm"
import Footer from "../components/Footer"

const IndexPage = () => {
  return (
    <>
      <Layout>
        <Navbar title="Advertising" />

        <Advertising />

        <ContactForm />
        <Footer />
      </Layout>
    </>
  )
}

export default IndexPage
